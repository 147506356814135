body 
{
  margin: 0;
}

.mapboxgl-control-container .mapboxgl-ctrl-top-left
{
  margin-top: 145px !important;
}

/* .mapboxgl-ctrl-geocoder {
  width: 250px!important;
} */

/*
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-fullscreen 
{
}
.mapboxgl-ctrl-top-right
{
}
.mapboxgl-ctrl-group
{
  background-color: red;
  border-radius: 15px;
}
.mapboxgl-ctrl-group button:focus
{
  box-shadow: none;
}
.mapboxgl-ctrl-group > .mapboxgl-ctrl-icon 
{
  width: 24px;
  height: 24px;
}
.mapboxgl-ctrl-group > .mapboxgl-ctrl-icon > button 
{
  width: 24px !important;
  height: 24px !important;
  border-radius: 2px !important;
}
.mapboxgl-ctrl-compass-arrow 
{
  margin: 0.1em 2px !important;
}
*/

/* .sidebar-top 
{
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 130px;
  left: 5px;    
} */

/* .coord-display 
{
  background-color: rgba(39, 74, 83, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  left: 0;
  margin: 0px 0px;
  border-radius: 4px;
  width:fit-content;
} */

/* .titlebar 
{
    background-color: rgb(0, 112, 160);
    color: rgb(255, 251, 218);
    padding: 2px 10px;
    font-family: monospace;
    font-size: 15pt;
    font-weight: bold;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 0;
  } */

/* .latLongTableTH 
{
  padding-left: 24px;
  padding-right: 24px;
} */

/* Custom thin scrollbar for webkit browsers (Chrome, Edge, Safari) */
::-webkit-scrollbar { width: 6px; height: 6px; background-color: 'transparent'; }
::-webkit-scrollbar-track { border-radius: 6px; background: #5B76A5; }    
/* background: #f1f1f1; */
::-webkit-scrollbar-thumb { border-radius: 6px; background: #8FA7D6; min-height: 24px; min-width: 24px; }
/* background: #B7B7B7; */
::-webkit-scrollbar-thumb:hover { background: #9fb5e0; }
::-webkit-scrollbar-thumb:focus { background: #9fb5e0; }
::-webkit-scrollbar-thumb:active { background: #9fb5e0; }
/* background: #8d8d8d; */
::-webkit-scrollbar-corner { background: 'transparent'; }



/* Custom thin scrollbar for Firefox (Works well on Win 11, doesn't work on Win 10) */
:root
{
  scrollbar-color: #9fb5e0 #5B76A5 !important;
  scrollbar-width: thin !important;
}